import { useState, useEffect, useRef } from 'react';
import { API_BASE_URL_V1 } from '../utils/constants';
import { fetchData } from '../utils/apiUtils';
import { getRandomExampleKeys } from '../utils/helper';

const useUserProfile = (isAuthenticated, user) => {
  const [userProfile, setUserProfile] = useState(null);
  const [userPreferences, setUserPreferences] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isNewUser, setIsNewUser] = useState(false);
  const [trendingSearchKeys, setTrendingSearchKeys] = useState([]);
  const [exampleSearchKeys, setExampleSearchKeys] = useState([]);
  const prevUserProfileIdRef = useRef();

  useEffect(() => {
    const fetchUserProfile = async () => {
      setIsLoading(true);
      try {
        if (!user?.email) {
          console.log('User details are not available');
          return; // Exit if user or email is not available
        }

        const profileEmailUrl = `${API_BASE_URL_V1}/user/profile/email`;
        const emailData = { email_id: user.email };

        const profileResponse = await fetch(profileEmailUrl, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(emailData)
        });

        if (!profileResponse.ok) {
          if (profileResponse.status === 404) {
            // Profile not found, create a new one
            const newUserProfile = {
              user_name: user.name,
              first_name: user.givenName,
              last_name: user.familyName,
              email_id: user.email,
              profile_name: 'OptionalProfileName'
            };

            const profileCreateUrl = `${API_BASE_URL_V1}/user/profile`;
            const profileCreateResponse = await fetch(profileCreateUrl, {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(newUserProfile)
            });

            if (!profileCreateResponse.ok) {
              throw new Error(`HTTP error! Status: ${profileCreateResponse.status}`);
            }

            const resultCreate = await profileCreateResponse.json();
            console.log('Profile created:', resultCreate);
            setUserProfile(resultCreate);
            setIsNewUser(true);
          } else {
            throw new Error(`HTTP error! Status: ${profileResponse.status}`);
          }
        } else {
          const result = await profileResponse.json();
          //console.log('Profile fetched:', result);
          if (result.detail === "User profile not found") {
            // Handle the case where the response is 200 but the profile is not found
            const newUserProfile = {
              user_name: user.name,
              first_name: user.givenName,
              last_name: user.familyName,
              email_id: user.email,
              profile_name: 'OptionalProfileName'
            };

            const profileCreateUrl = `${API_BASE_URL_V1}/user/profile`;
            const profileCreateResponse = await fetch(profileCreateUrl, {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(newUserProfile)
            });

            if (!profileCreateResponse.ok) {
              throw new Error(`HTTP error! Status: ${profileCreateResponse.status}`);
            }

            const resultCreate = await profileCreateResponse.json();
            console.log('Profile created:', resultCreate);
            setUserProfile(resultCreate);
            setIsNewUser(true);
          } else {
            console.log('Profile fetched:', result);
            setUserProfile(result);
            const user_profile_id = result.id;

            if (user_profile_id) {
              // Fetch user preferences if profile exists
              await fetchUserPreferences(user_profile_id);
            }
          }
        }
      } catch (error) {
        console.error('Error managing user profile:', error);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchUserPreferences = async (user_profile_id) => {
      const userPreferencesUrl = `${API_BASE_URL_V1}/user/preferences/${user_profile_id}`;

      try {
        const userPrefResponse = await fetch(userPreferencesUrl, {
          method: "GET",
          headers: { "Content-Type": "application/json" }
        });

        if (userPrefResponse.ok) {
          const userPrefResults = await userPrefResponse.json();
          console.log('User preferences fetched:', userPrefResults);
          setUserPreferences(userPrefResults);
        } else if (userPrefResponse.status === 404) {
          console.log('User preferences not found.');
          setUserPreferences(null); // Set userPreferences to null if not found
        } else {
          throw new Error(`HTTP error! Status: ${userPrefResponse.status}`);
        }
      } catch (error) {
        console.error('Error fetching user preferences:', error);
      }
    };

    if (isAuthenticated && !userProfile && user?.email) {
      fetchUserProfile();
    }
  }, [isAuthenticated, user, userProfile]);

  useEffect(() => {
    const getTrendingSearchKeys = async () => {
      if (!trendingSearchKeys || trendingSearchKeys.length === 0) {
        const searchKeys = await fetchTrendingSearchKeys(userProfile.id);
        setTrendingSearchKeys(searchKeys);
      }
    };

    if (userProfile?.id && prevUserProfileIdRef.current !== userProfile.id) {
      getTrendingSearchKeys();
      prevUserProfileIdRef.current = userProfile.id;
    }
  }, [userProfile?.id, trendingSearchKeys]);

  useEffect(() => {
    // Set random example keys when trendingSearchKeys changes
    const randomExampleKeys = getRandomExampleKeys(5, trendingSearchKeys);
    setExampleSearchKeys(randomExampleKeys);
  }, [trendingSearchKeys]);

  const fetchTrendingSearchKeys = async (userProfileId) => {
    try {
      const url = `${API_BASE_URL_V1}/searchlookup/trending?user_profile_id=${userProfileId}`;
      const data = await fetchData(url);
      return data;
    } catch (error) {
      console.error('Failed to fetch trending search keys:', error);
      return [];
    }
  };

  const updatePreferences = async (user_profile_id, preferences, is_modified) => {
    if (!user_profile_id) {
      console.error('User profile is not available');
      return;
    }
    let url = `${API_BASE_URL_V1}/user/preferences`;
    if (is_modified) {
      url = `${API_BASE_URL_V1}/user/preferences/update`;
    }

    const preferencesData = {
      user_profile_id: user_profile_id,
      conversational_style: preferences.conversational_style,
      personal_fashion_style: preferences.personal_fashion_style,
      favorite_colors: preferences.favorite_colors,
      fashion_material_prefer: preferences.fashion_material_prefer,
      body_type: preferences.body_type,
      fashion_goals: preferences.fashion_goals,
      age_group: preferences.age_group
    };

    try {
      console.log('useUserProfile - preferencesData -->', preferencesData)
      console.log('useUserProfile - user_profile_id -->', user_profile_id)
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(preferencesData)
      };
      const result = await fetchData(url, requestOptions);
      console.log('Preferences update successful:', result);
      setUserPreferences(result);
      return result; // Return the updated preferences if needed
    } catch (error) {
      console.error('Failed to save or update preferences:', error);
      throw error; // Rethrow the error to handle it in the UI if needed
    }
  };

  const preferencesHaveChanged = (initialData, currentData) => {
    console.log('useUserProfile - initialData -->', initialData)
    console.log('useUserProfile - currentData -->', currentData)
    return Object.keys(initialData).some(key => {
      if (key === 'id' || key === 'user_profile_id' || key === 'is_modified') {
        return false;
      }
      if (Array.isArray(initialData[key]) && Array.isArray(currentData[key])) {
        const initialSet = new Set(initialData[key]);
        const currentSet = new Set(currentData[key]);
        if (initialSet.size !== currentSet.size) return true;
        for (let item of currentSet) {
          if (!initialSet.has(item)) return true;
        }
      } else {
        if (initialData[key] !== currentData[key]) {
          return true;
        }
      }
      return false;
    });
  };

  return { userProfile, userPreferences, setUserPreferences, isLoading, isNewUser, trendingSearchKeys, exampleSearchKeys, updatePreferences, preferencesHaveChanged };
};

export default useUserProfile;
