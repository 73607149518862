import React, { useState, useEffect, useRef } from 'react';
import { ChromePicker } from 'react-color';
import { useSession, useUser, useDescope } from '@descope/react-sdk';
import ColorWheelIcon from "../../assets/images/color-wheel-icon.png";
import { BoundingBox, handleImageData } from '../../components/bounding-box';
import { BoundingBoxRo } from '../../components/bounding-box-ro';
import ProductCard from '../../components/product-card';
import MenuComponent from '../../components/menu';
import IntroductionComponent from '../../components/introduction-component';
import AuthenticationComponent from '../../components/authentication';
import WebcamCaptureComponent from '../../components/webcam-capture';
import ImageOptionDialogComponent from '../../components/image-option-dailog';
import useUserProfile from '../../hooks/useUserProfile';
import { fetchImageData } from '../../utils/axios-utils';
import { fetchData } from '../../utils/apiUtils';
import { API_BASE_URL_V0, API_BASE_URL_V1 } from '../../utils/constants';
import { initializeSession, fetchIpAddress } from '../../utils/helper';
import GetPreferences from '../../pages/preferences';

const HomeComponent = () => {
	const { isAuthenticated, isSessionLoading } = useSession();
	const { user, isUserLoading } = useUser();
	const { logout } = useDescope();
	const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
//	const defaultText = "Search for women's fashion items";
	const defaultText = "Message shopperGPT";
	const defaultModText = 'Modify this search?';
	const [customImage, setCustomImage] = useState(null);
	const [customImageRo, setCustomImageRo] = useState(null);
	const [prompt, setPrompt] = useState(defaultText);
	const [showModifyContainer, setShowModifyContainer] = useState(false);
	const [showResponseContainer, setShowResponseContainer] = useState(false);
	const [response, setResponse] = useState(false);
	const [responseText, setResponseText] = useState('');
	const [showImagePlaceholder, setShowImagePlaceholder] = useState(false);
	const [showCustomImage, setShowCustomImage] = useState(false);
	const [showCustomImageRo, setShowCustomImageRo] = useState(false);
	const [isImageIconActive, setImageIconActive] = useState(false);
	const [modPrompt, setModPrompt] = useState(defaultModText);
	const [promptInteracted, setPromptInteracted] = useState(false);
	const [isFocused, setIsFocused] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [showColorPicker, setShowColorPicker] = useState(false);
	const [hsvColor, setHsvColor] = useState({ h: 0, s: 0, v: 1, a: 1 });
	const [colorPickerUsed, setColorPickerUsed] = useState(false);
	const [actionFocus, setActionFocus] = useState('generate');
	const [animate, setAnimate] = useState(false);
	const [animateImageIcon, setAnimateImageIcon] = useState(false);
	const [isDismissed, setIsDismissed] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [initialLoad, setInitialLoad] = useState(true); // To track if it's the initial load
	const [isTrendDisplay, setIsTrendDisplay] = useState(false);
	const [showPreferences, setShowPreferences] = useState(false);
	const [showWebcam, setShowWebcam] = useState(false);
	const [showImageOptions, setShowImageOptions] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const dropdownRef = useRef(null);
	const { userProfile, userPreferences, setUserPreferences, isLoading: profileLoading, isNewUser, trendingSearchKeys, exampleSearchKeys } = useUserProfile(isAuthenticated, user); // Use the hook

	useEffect(() => {
		initializeSession();  // Initialize session ID when the component mounts
		fetchIpAddress(); // Added call to fetch IP address on mount
	}, []);

	const handleDeScopeSuccess = async (e) => {
		//console.log('handleDeScopeSuccess -->',e.detail.user);
		if (!userProfile && e.detail.user?.email) {
			const profileEmailUrl = `${API_BASE_URL_V1}/user/profile/email`;
			const emailData = { email_id: e.detail.user?.email };

			const profileResponse = await fetch(profileEmailUrl, {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(emailData)
			});
			const result = await profileResponse.json();

			const current_hour = new Date().getHours();
			const queryData = {
				current_hour: current_hour,
				userPrefsNotPresent: false,
				user_name: result?.user_name,
				user_profile_id: result?.id,
				limit: 40
			};

			const requestOptions = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'accept': 'application/json'
				},
				body: JSON.stringify(queryData)
			};

			try {
				const url = `${API_BASE_URL_V1}/user/preferences/randomdata`;
				const userPrefResults = await fetchData(url, requestOptions);
				console.log('userPrefResults -->', userPrefResults);
				if (userPrefResults.results) {
					const { greeting, data } = userPrefResults.results;
					if (data && data.length > 0) {
						setResponseText(greeting);
						setShowResponseContainer(true);
						setResponse(data);
						setPromptInteracted(true);
						setShowImagePlaceholder(true);
						setIsLoading(false);
					}
				}

			} catch (error) {
				console.error('Error fetching user preferences random results:', error);
			}
			setInitialLoad(false);
		}
	};

	const handleImageIconClick = (event) => {
		setShowColorPicker(false);
		//setShowResponseContainer(false);
		setShowCustomImage(false);
		setShowCustomImageRo(false);
		//setShowImagePlaceholder(false);
		setShowModifyContainer(false);
		setShowImageOptions(true);
		setIsTrendDisplay(false);
		setPrompt(defaultText);
		setModPrompt("");
		setAnimateImageIcon(true);
		setTimeout(() => setAnimateImageIcon(false), 250);
		setImageIconActive(!isImageIconActive);
		setAnchorEl(event.currentTarget);        
	};

	const handleProductImageClick = async (productUrl) => {
		//window.open(productUrl, '_blank');
	}

	const handleProductIconClick = async (productLink) => {
		try {
			const { imageDataUrl, arrayBuffer, contentType } = await fetchImageData(productLink);
			handleImageData(imageDataUrl, setCustomImageRo); // Update custom image state
			setCustomImageRo(imageDataUrl);
			setShowCustomImageRo(true);
			setShowCustomImage(false);
			setShowImagePlaceholder(false);
			setShowModifyContainer(false);
			setShowColorPicker(false);
			setImageIconActive(false);
			setShowResponseContainer(false);
			setShowImageOptions(false);
			setErrorMessage('');
			setPrompt(defaultText);

			// Image API call
			try {
				const formData = new FormData();
				formData.append('file', new File([arrayBuffer], 'image.jpg', { type: contentType }));
				const sessionId = localStorage.getItem('session_id');
				const client_ip = localStorage.getItem('client_ip');
				const location = localStorage.getItem('client_location');

				formData.append('limit', '40');
				formData.append('session_id', sessionId);
				formData.append('client_ip', client_ip);
				formData.append('location', location);
				formData.append('user_name', userProfile?.user_name);
				formData.append('user_profile_id', userProfile?.id);

				setIsLoading(true);

				const url = process.env.REACT_APP_API_V1 + '/product/search/image';
				const requestOptions = {
					method: 'POST',
					body: formData
				};

				const result = await fetchData(url, requestOptions);
				console.log("Received the following response:", result);
				console.log("result.results:", result.results);
				setResponse(result.results);
				setShowImagePlaceholder(true);

			} catch (error) {
				console.error('Failed to call image API:', error);
			} finally {
				setIsLoading(false);
			}
		} catch (error) {
			console.error('Error:', error);
		}
	};

	const handleInputChange = (event) => {
		setPrompt(event.target.value);
		setErrorMessage('');
		if (event.target.value.length >= 3) {
			setIsTrendDisplay(false)
		} else {
			setIsTrendDisplay(true)
		}
	};
	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			if (actionFocus === 'generate') {
				handleGenerateClick();
				setErrorMessage('');
			} else if (actionFocus === 'modify') {
				// handleModifyClick();
				setErrorMessage('');
			}
		}
	};
	const handleFileChange = (event) => {
		const file = event.target.files[0];
		console.log('handleFileChange --file->', file)
		setErrorMessage('');
		if (file) {
			const reader = new FileReader();
			reader.onload = function (e) {

				setCustomImage(e.target.result);
				setShowCustomImage(true);
				setShowCustomImageRo(false);
				setShowImagePlaceholder(false);
				setShowModifyContainer(false);
				setShowColorPicker(false);
				setImageIconActive(false);
				setShowResponseContainer(false);
				setShowImageOptions(false);
				setErrorMessage('');
			};
			console.log(`showResponseContainer set to ${false}`);
			reader.readAsDataURL(file);
		}
	};

	const handleUploadOpen = () => {
		document.getElementById("file-input").click();
		setShowImageOptions(false);
		setShowColorPicker(false);
		setShowResponseContainer(false);
		setShowModifyContainer(false);
		setShowCustomImage(false);
		setShowCustomImageRo(false);
		setShowImagePlaceholder(false);
	};

	const handleCropData = (data) => {
		console.log('Received crop data:', data);
		setImageDimensions(data);
		setErrorMessage('');
	};

	const sendCropDataToBackend = async (cropData) => {
		console.log('sendCropDataToBackend -cropData:', cropData);
		try {
			const formData = new FormData();

			const base64String = customImage.split(',')[1];
			const byteCharacters = atob(base64String);
			const byteNumbers = new Array(byteCharacters.length);
			for (let i = 0; i < byteCharacters.length; i++) {
				byteNumbers[i] = byteCharacters.charCodeAt(i);
			}
			const byteArray = new Uint8Array(byteNumbers);
			const file = new Blob([byteArray], { type: 'image/jpeg' });
			formData.append('file', file, 'image.jpg');
			const sessionId = localStorage.getItem('session_id');
			const client_ip = localStorage.getItem('client_ip');
			const location = localStorage.getItem('client_location');

			if (imageDimensions) {
				const cropValuesArray = [imageDimensions.x, imageDimensions.y, imageDimensions.width, imageDimensions.height].map(Number);
				formData.append('crop_values', JSON.stringify(cropValuesArray));
			}
			formData.append('limit', '40');
			formData.append('session_id', sessionId)
			formData.append('client_ip', client_ip)
			formData.append('location', location)
			formData.append('user_name', userProfile?.user_name)
			formData.append('user_profile_id', userProfile?.id)

			setIsLoading(true);
			setShowResponseContainer(false);
			console.log(`showResponseContainer set to ${false}`);
			const response = await fetch(
				process.env.REACT_APP_API_V1 + "/product/search/image",
				{ method: "POST", body: formData }
			);

			if (!response.ok) throw new Error('Upload failed');
			const result = await response.json();
			console.log("Received the following response:", result);
			console.log("result.results:", result.results);
			setResponse(result.results);
			setShowImagePlaceholder(true);

		} catch (error) {
			console.error('Failed to send crop data to backend:', error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleSendCropDataClick = () => {
		if (imageDimensions.width && imageDimensions.height) {
			sendCropDataToBackend(imageDimensions);
		} else {
			console.log('No crop data to send');
		}
	};

	const handleColorWheelIconClick = () => {
		setIsTrendDisplay(false);
		//setShowResponseContainer(false);
		//setShowImagePlaceholder(false);
		console.log(`showResponseContainer set to ${false}`);
		if (showCustomImage) {
			setShowCustomImage(false);
			setShowResponseContainer(false);
			console.log(`showResponseContainer set to ${false}`);
			document.getElementById('file-input').value = '';
		}
		if (showCustomImageRo) {
			setShowCustomImageRo(false);
		}

		setShowModifyContainer(false);
		setShowColorPicker(!showColorPicker);
		if (showColorPicker) {

			setHsvColor({ h: 0, s: 0, v: 1, a: 1 });
			setColorPickerUsed(false);
		}
		setShowModifyContainer(false);
		//setShowResponseContainer(false);
		console.log(`showResponseContainer set to ${false}`);
		setErrorMessage("");
	};
	const handleColorChange = (color) => {
		const { h, s, v, a } = color.hsv;
		setHsvColor({ h, s, v, a });
		setColorPickerUsed(true);
	};
	const handleFocus = async () => {
		setIsFocused(true);
		// setPrompt('');
		setModPrompt(defaultModText);
		setShowModifyContainer(false);
		setShowCustomImage(false);
		setShowCustomImageRo(false);
		//setShowResponseContainer(false);
		console.log(`showResponseContainer set to ${false}`);
		document.getElementById('file-input').value = '';
		//setShowImagePlaceholder(false);
		if (prompt === defaultText) {
			setPrompt('');
		}
		if (showColorPicker) {
			setIsTrendDisplay(false);
		} else {
			setIsTrendDisplay(true);
		}

	};
	const handleBlur = () => {
		setIsFocused(false);
		setErrorMessage("");
		setShowCustomImage(false);
		setShowCustomImageRo(false);
		document.getElementById('file-input').value = '';
		if (!prompt && !promptInteracted) {
			setPrompt(defaultText);
		}
	};

	const handleSelectTrendingItem = (item) => {
		setPrompt(item);
		console.log('Selected Trending Item:', item);
		if (showColorPicker) {
			setShowColorPicker(false);
		}
	};

	useEffect(() => {
		if (!isFocused && !prompt && !promptInteracted === '') {
			setPrompt(defaultText);
		}
	}, [isFocused, prompt, defaultText, promptInteracted]);

	useEffect(() => {
		function handleClickOutside(event) {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setIsTrendDisplay(false);
			}
		}

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [dropdownRef]);

	const handleModFocus = () => {
		setIsFocused(true);
		if (modPrompt === defaultModText) {
			setModPrompt('');
		}
	};

	const handleModBlur = () => {
		setIsFocused(false);
		setErrorMessage("");
		if (!modPrompt) {
			setModPrompt(defaultModText);
		}
	};

	useEffect(() => {
		if (!isFocused && modPrompt.trim() === '') {
			setModPrompt(defaultModText);
		}
	}, [isFocused, modPrompt, defaultModText]);

	const handleGenerateClick = async (customPrompt = '') => {
		if (showCustomImageRo) {
			return;
		}
		setIsTrendDisplay(false);
		setResponseText(false);
		setShowResponseContainer(false);
		setShowImagePlaceholder(false);
		console.log(`showResponseContainer set to ${false}`);
		setAnimate(true); // Start the animation
		setTimeout(() => setAnimate(false), 250); // Animation duration 1s
		let combinedPrompt = '';
		if (customPrompt) {
			// Use the custom prompt if provided
			combinedPrompt = customPrompt.trim();
			//setPrompt(combinedPrompt);
		} else if (modPrompt.trim() !== defaultModText.trim()) {
			combinedPrompt = prompt.trim() + " " + modPrompt.trim(); //`${prompt} ${modPrompt}`.trim();
		} else if (prompt.trim() !== defaultText.trim()) {
			combinedPrompt = prompt.trim();
		}

		console.log('Generating with combined prompt:', combinedPrompt);
		console.log('Generating with prompt:', prompt);
		console.log('Generating with hsvColor:', hsvColor);
		console.log('handleGenerateClick - showCustomImage:', showCustomImage);

		if (showCustomImage) {
			setShowResponseContainer(false);
			console.log(`showResponseContainer set to ${false}`);
			return handleSendCropDataClick();
		}

		const isPromptEmpty = (!prompt || prompt.trim() === '' || prompt === defaultText) && (!customPrompt || customPrompt.trim() === '');
		const isCombinedPromptEmpty = combinedPrompt === '' || combinedPrompt === `${defaultText} ${defaultModText}`.trim() || combinedPrompt === `${defaultModText}`.trim();
		console.log('prompt -->', prompt)
		console.log('combinedPrompt -->', combinedPrompt)
		console.log('modPrompt -->', modPrompt)
		console.log('isCombinedPromptEmpty -->', isCombinedPromptEmpty)
		const shouldUseColorApi = showColorPicker && colorPickerUsed;
		const shouldUseTextApi = !showColorPicker || isPromptEmpty;
		if (
			isPromptEmpty &&
			hsvColor.h === 0 &&
			hsvColor.s === 0 &&
			hsvColor.v === 1 &&
			hsvColor.a === 1
		) {
			setErrorMessage(
				"Please select at least Image or Text or Color or Text & Color."
			);
			setResponse(null);
			setIsLoading(false);
			return;
		} else {
			setErrorMessage("")
		}

		setIsLoading(true);
		let limit = 40;
		let url = '';
		let data = {};
		const sessionId = localStorage.getItem('session_id');
		const client_ip = localStorage.getItem('client_ip');
		const location = localStorage.getItem('client_location');

		if (!isCombinedPromptEmpty && !shouldUseColorApi) {
			handleFetchQueryResponse();
		}
		if (shouldUseTextApi && !shouldUseColorApi) {
			url = process.env.REACT_APP_API_V0 + '/product/search/text';
			data = initialLoad ? {
				query_str: combinedPrompt,
				preperences: true,
				user_name: userProfile.user_name,
				user_profile_id: userProfile.id,
				conversational_style: userPreferences.conversational_style,
				personal_fashion_style: userPreferences.personal_fashion_style,
				favorite_colors: userPreferences.favorite_colors,
				fashion_material_prefer: userPreferences.fashion_material_prefer,
				body_type: userPreferences.body_type,
				fashion_goals: userPreferences.fashion_goals,
				age_group: userPreferences.age_group,
				session_id: sessionId,
				client_ip: client_ip,
				location: location,
				limit: limit
			} : {
				query_str: combinedPrompt,
				preperences: false,
				user_name: userProfile.user_name,
				user_profile_id: userProfile.id,
				session_id: sessionId,
				client_ip: client_ip,
				location: location,
				limit: limit
			};
			setShowResponseContainer(true);
		} else if (shouldUseColorApi) {
			setShowResponseContainer(false)
			url = `${API_BASE_URL_V0}/product/search/textandcolor`;
			const backendColorFormat = [Math.round(hsvColor.h), Math.round(hsvColor.s * 100), Math.round(hsvColor.v * 100)];
			data = {
				query_str: isCombinedPromptEmpty ? undefined : combinedPrompt,
				color: JSON.stringify(backendColorFormat),
				user_name: userProfile?.user_name,
				user_profile_id: userProfile?.id,
				session_id: sessionId,
				client_ip: client_ip,
				location: location,
				limit: limit
			};
		}

		try {
			const result = await fetchData(url, {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(data)
			});

			if (result.results && result.results.length > 0) {
				setResponse(result.results);
				setPromptInteracted(true);
			} else {
				console.error('Unexpected response structure:', result);
			}
		} catch (error) {
			console.error('Error when calling the local API endpoint:', error);
		} finally {
			setIsLoading(false);
		}

		setTimeout(() => {
			setShowModifyContainer(!showColorPicker);
			setShowImagePlaceholder(true);
		}, 350);
	};

	const handleFetchQueryResponse = async () => {
		const url = `${API_BASE_URL_V0}/product/search/queryresponse`;
		let combinedPrompt = '';
		if (modPrompt.trim() !== defaultModText.trim()) {
			combinedPrompt = `${prompt} ${modPrompt}`.trim();
		} else if (prompt.trim() !== defaultText.trim()) {
			combinedPrompt = prompt.trim();
		}

		const current_hour = new Date().getHours();

		const queryData = initialLoad ? {
			query_str: 'Initial Request',
			preperences: true,
			userPrefsNotPresent: false,
			current_hour: current_hour,
			user_name: userProfile.user_name,
			user_profile_id: userProfile.id,
			conversational_style: userPreferences.conversational_style,
			personal_fashion_style: userPreferences.personal_fashion_style,
			favorite_colors: userPreferences.favorite_colors,
			fashion_material_prefer: userPreferences.fashion_material_prefer,
			body_type: userPreferences.body_type,
			fashion_goals: userPreferences.fashion_goals,
			age_group: userPreferences.age_group
		} : {
			query_str: combinedPrompt,
			preperences: false,
			conversational_style: userPreferences?.conversational_style,
			user_name: userProfile.user_name,
			user_profile_id: userProfile.id
		};

		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'accept': 'application/json'
			},
			body: JSON.stringify(queryData)
		};

		try {
			setIsLoading(true);
			const queryPrefResults = await fetchData(url, requestOptions);
			console.log('queryPrefResults -->', queryPrefResults);

			// Check if the results array is empty or not
			if (!queryPrefResults) {
				console.log('No results found');
				setResponseText('No results found')
			} else {
				const { greeting, recommendation } = queryPrefResults.results;
				// Construct the result text
				const resultText = (greeting ? greeting + " " : "") + recommendation;
				console.log('resultText -->', resultText);
				// Update the UI with the combined result text
				setResponseText(resultText);
			}

		} catch (error) {
			console.error('Failed to fetch data:', error);
			setErrorMessage('Failed to fetch data');
		} finally {
			setIsLoading(false);
		}
	};

	const handleCapture = (imageSrc) => {
		setShowWebcam(false);
		setCustomImage(imageSrc);
		setShowCustomImage(true);
		setShowCustomImageRo(false);
		setShowImagePlaceholder(false);
		setShowModifyContainer(false);
		setShowColorPicker(false);
		setImageIconActive(false);
		setShowResponseContainer(false);
		setShowImageOptions(false);
		setErrorMessage("");
	};

	const handleWebcamOpen = () => {
		setShowWebcam(true);
		setShowImageOptions(false);
		setShowColorPicker(false);
		setShowResponseContainer(false);
		setShowModifyContainer(false);
		setShowCustomImage(false);
		setShowCustomImageRo(false);
		setShowImagePlaceholder(false);
	}

	const handleDismiss = async () => {
		console.log('Dismissed');
		setIsDismissed(true);
		setIsTrendDisplay(false);
		if (userProfile) {
			// Call new API to load the random products based on the user preferences
			const current_hour = new Date().getHours();
			const queryData = {
				current_hour: current_hour,
				userPrefsNotPresent: false,
				user_name: userProfile?.user_name,
				user_profile_id: userProfile?.id,
				limit: 40
			};

			const requestOptions = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'accept': 'application/json'
				},
				body: JSON.stringify(queryData)
			};

			try {
				const url = `${API_BASE_URL_V1}/user/preferences/randomdata`;
				const userPrefResults = await fetchData(url, requestOptions);
				console.log('userPrefResults -->', userPrefResults);
				const { greeting, data } = userPrefResults.results;
				if (data && data.length > 0) {
					setResponseText(greeting);
					setShowResponseContainer(true);
					setResponse(data);
					setPromptInteracted(true);
					setShowImagePlaceholder(true);
					setIsLoading(false);
				}
			} catch (error) {
				console.error('Error fetching user preferences random results:', error);
			}

			setInitialLoad(false);
		}

	};

	useEffect(() => {
		const fetchRandomProducts = async () => {
			if (userProfile) {
				// Call new API to load the random products based on the user preferences
				const current_hour = new Date().getHours();
				const queryData = {
					current_hour: current_hour,
					userPrefsNotPresent: false,
					user_name: userProfile?.user_name,
					user_profile_id: userProfile?.id,
					limit: 40
				};

				const requestOptions = {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'accept': 'application/json'
					},
					body: JSON.stringify(queryData)
				};

				try {
					const url = `${API_BASE_URL_V1}/user/preferences/randomdata`;
					const userPrefResults = await fetchData(url, requestOptions);
					console.log('userPrefResults -->', userPrefResults);
					const { greeting, data } = userPrefResults.results;
					if (data && data.length > 0) {
						setResponseText(greeting);
						setShowResponseContainer(true);
						setResponse(data);
						setPromptInteracted(true);
						setShowImagePlaceholder(true);
						setIsLoading(false);
					}
				} catch (error) {
					console.error('Error fetching user preferences random results:', error);
				}

				setInitialLoad(false);
			}
		};

		if (isAuthenticated && userProfile && initialLoad) {
			fetchRandomProducts();
		}
	}, [isAuthenticated, userProfile, initialLoad]);

	const handleLogout = () => {
		logout();
	};

	const handleUserPreferences = () => {
		setShowPreferences(true);
		setIsSubmitted(false);
	};

    const handleImgOptClose = () => {
        setAnchorEl(null);
        setShowImageOptions(false);
    };
	
	if (!isDismissed && isNewUser) {
		return <IntroductionComponent isLoading={isLoading} handleDismiss={handleDismiss} />;
	} else if (!isAuthenticated) {
		return <AuthenticationComponent handleDeScopeSuccess={handleDeScopeSuccess} />;
	} else if (isSessionLoading || isUserLoading || profileLoading) {
		return <p>Loading...</p>;
	} else if (isAuthenticated && !isSubmitted && showPreferences) {
		return (
			<GetPreferences
				userProfile={userProfile}
				userPreferences={userPreferences}
				setUserPreferences={setUserPreferences}
				setIsSubmitted={setIsSubmitted}
				setShowResponseContainer={setShowResponseContainer}
				setResponseText={setResponseText}
				setResponse={setResponse}
				setInitialLoad={setInitialLoad}
				setShowImagePlaceholder={setShowImagePlaceholder}
				setPromptInteracted={setPromptInteracted}
				setErrorMessage={setErrorMessage}
				setIsTrendDisplay={setIsTrendDisplay}
				setShowPreferences={setShowPreferences}
			/>
		);
	} else {
		// Show the main UI here
	}
	
	return (
		<div className="app">
			<div className="header-container">
				<div className="shopbot-title">
					<span className="sibi-text">Shopper</span>
					<span className="genai-text">GPT</span>
					<MenuComponent
						className="menu-component"
						onLogout={handleLogout}
						onPreferences={handleUserPreferences}
					/>
				</div>
			</div>

			<div className="prompt-container">
				<textarea className="prompt-input"
					value={prompt}
					onChange={handleInputChange}
					onKeyDown={handleKeyDown}
					onFocus={handleFocus}
					onBlur={handleBlur}
					onInput={event => {
						event.target.style.height = 'auto';
						event.target.style.height = `${event.target.scrollHeight}px`;
					}}					
				/>
				<button className={`color-wheel-icon ${showColorPicker ? 'depressed' : ''}`}
					onClick={handleColorWheelIconClick} >
					<img src={ColorWheelIcon} alt="Color wheel" className="icon" />
				</button>
				<button onClick={handleImageIconClick} className={`image-icon-button ${animateImageIcon ? 'animate' : ''}`}>
					<img src={animateImageIcon ? "image-icon1a.png" : "image-icon.png"} alt="Upload" className="image-icon" />
				</button>
				<button onClick={() => handleGenerateClick()} className={`arrow-icon-button ${animate ? 'animate' : ''}`}
					onFocus={() => setActionFocus('generate')}>
					<img src={animate ? "arrow-icon1b.png" : "arrow-icon1a.png"} alt="Arrow Icon" className="arrow-icon" />
				</button>
				{/* Display Trending Search Keys */}
				{isTrendDisplay && (
					<div className="recent-searches-dropdown" ref={dropdownRef}>
						{trendingSearchKeys.length > 0 && (
							<>
								<h3>Recent searches</h3>
								<ul>
									{trendingSearchKeys.map((item, index) => (
										<li key={`trending-${index}`}>
											<span
												onClick={() => handleSelectTrendingItem(item.query_string)}
												style={{ cursor: "pointer" }}
											>
												{item.query_string}
											</span>
										</li>
									))}
								</ul>
							</>
						)}
						{exampleSearchKeys.length > 0 && (
							<>
								<h3>Example searches</h3>
								<ul>
									{exampleSearchKeys.map((item, index) => (
										<li key={`example-${index}`}>
											<span
												onClick={() => handleSelectTrendingItem(item)}
												style={{ cursor: "pointer" }}
											>
												{item}
											</span>
										</li>
									))}
								</ul>
							</>
						)}
					</div>
				)}
			</div>
			<input type="file" id="file-input" style={{ display: 'none' }} onChange={handleFileChange} />
			{showModifyContainer && (
				<div className="modify-container">
					<textarea className="prompt-input"
						value={modPrompt}
						onChange={(e) => setModPrompt(e.target.value)}
						onKeyDown={handleKeyDown}
						onFocus={handleModFocus}
						onBlur={handleModBlur}
						onInput={event => {
							event.target.style.height = 'auto';
							event.target.style.height = `${event.target.scrollHeight}px`;
						}}
					/>
				</div>
			)}
			{showCustomImage && (
				<div className="custom-image-placeholder">
					<BoundingBox customImage={customImage} onCropData={handleCropData} />
				</div>
			)}
			{showCustomImageRo && (
				<div className="custom-image-placeholder">
					<BoundingBoxRo customImage={customImageRo} />
				</div>
			)}
			<ImageOptionDialogComponent
                open={showImageOptions}
                anchorEl={anchorEl}
                onClose={handleImgOptClose}
                onUpload={handleUploadOpen}
                onWebcam={handleWebcamOpen}
            />
			<WebcamCaptureComponent
				open={showWebcam}
				onClose={() => setShowWebcam(false)}
				onCapture={handleCapture}
			/>
			{showColorPicker && (
				<div className="color-picker-container"> { }
					<ChromePicker
						color={hsvColor}
						onChangeComplete={handleColorChange} />
				</div>
			)}
			{errorMessage && <div className="error-message">{errorMessage}</div>}
			{showResponseContainer && (
				<div className="response-container">
					<div className="response-text">
						{responseText}
					</div>
					<div className="recommendations">
						<span className="rec-text">{`Here's some possible choices:`}</span>
					</div>
				</div>
			)}
			{isLoading && <div className="loader"></div>}
			{showImagePlaceholder && (
				<div className="image-placeholder-container">
					{!isLoading && response && response.map((product, index) => (
						<ProductCard
							key={index}
							product={product}
							handleProductImageClick={() => handleProductImageClick(product.url)}
							handleProductIconClick={() => handleProductIconClick(product.link)}
						/>
					))}
				</div>
			)}
		</div>
	);
};
export default HomeComponent;
